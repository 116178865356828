/* src/styles/main.css */
  body {
    font-family: 'Segoe UI', sans-serif;
    margin: 0;
    padding: 0;
    background: white;
    color: #263446;
  }
  
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; 
    gap: 20px;
    margin: 20px auto;
    max-width: 1200px;
    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f7f7f7;
    padding: 5px 20px;
    display: flex;
    flex-direction: column; /* Display elements in a column layout */
    align-items: left;
    text-align: left;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    max-height: 400px; /* Add a maximum height for scrollable content */
    overflow: auto; /* Add scroll when content exceeds the maximum height */
  }
  
  .cookie-banner p {
    margin-left: 1rem;
  }

  .cookie-banner-button-container {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  
  .cookie-banner button {
    background-color: #0b54fc;
    color: white;
    border: none;
    padding: 0.8rem 1.1rem;
    font-size: 1.1rem;
    /*font-weight: bold;*/
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.2s ease-in-o
  }
  
  .cookie-banner button:not(:last-child) {
    margin-right: 10px;
  }
  
  .cookie-banner button:hover {
    background-color: #15c7fa;
  }

  .cookie-banner-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #263446;
    margin-bottom: 0.5rem;
  }

  .cookie-banner-description {
    font-size: 1.1rem;
    margin-top: 0rem;
    margin-bottom: 1rem;
    color: #263446;
    line-height: 1.5; 
    max-width: 80%; /* Limit the maximum width for smaller screens */
    padding: 0px 0; /* Add some padding for spacing */
  }
  
  .header {
    text-align: center;
    padding: 3rem;
    color: white;
    background: rgb(11,84,252);
    background: linear-gradient(90deg, rgba(11,84,252,1) 0%, rgba(21,199,250,1) 100%);
    margin-bottom: 1rem;
  }

  /* src/styles/BrandName.css */
  .brand-name {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
    padding: 1rem 1rem;
    background: -webkit-linear-gradient(#0b54fc, #15c7fa);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .brand-link {
    text-decoration: none;
  }

  .main-content {
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.5;
    max-width: 80%;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .main-heading {
    font-size: 2rem;
    color: #263446;
    margin-bottom: 1rem;
  }
  
  .section-heading {
    font-size: 1.5rem;
    color: #263446;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  /* Stil für nummerierte Listenpunkte */
  ol {
    counter-reset: list-counter; /* Setzt den Zähler für die Listen zurück */
    list-style-type: none; /* Entfernt die standardmäßige Nummerierung */
  }

  ul{
    list-style-type: none;
  }
  
  .custom-list li {
    counter-increment: list-counter; /* Erhöht den Zähler für jeden Listenpunkt */
    margin-bottom: 10px; /* Abstand zwischen den Listenpunkten */
  }
  
  .custom-list li::before {
    content: counter(list-counter) ". "; /* Fügt die Nummerierung vor jedem Listenpunkt ein */
    font-weight: normal; /* Betont die Nummerierung */
    margin-right: 5px; /* Abstand zwischen Nummerierung und Text */
  }
  

  .subtext {
    font-size: 1.1rem;
    margin-top: 0.5rem;
    color: white;
  }
  
  .get-started-button {
    background-color: #0b54fc;
    color: white;
    border: none;
    padding: 0.8rem 1.1rem;
    font-size: 1.1rem;
    /*font-weight: bold;*/
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .get-started-button:hover {
    background-color: #15c7fa;
  }
  
  .screenshot {
    display: flex;
    justify-content: center; 
    align-items: center;
    padding: 2rem;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .screenshot img.left-margin {
    max-width: 100%;
    height: auto;
    width: auto;
    max-height: 500px; 
    margin-left: 3rem;
  }
  
  .screenshot img.right-margin {
    max-width: 100%;
    height: auto;
    width: auto;
    max-height: 500px;
    margin-right: 3rem;
  }
  
  .screenshot-content {
    text-align: left;
    max-width: 100%;
  }
  
  
  .screenshot-title {
    font-size: 2rem;
    font-weight: bold;
    color: #263446;
    margin: 0rem 0 0; /* Add margin-top, remove margin-bottom */
    padding: 0; /* Remove padding */
  }

  .screenshot-subtitle {
    font-size: 1.1rem;
    color: #263446;
    line-height: 1;
    margin: 0.3rem 0 0; /* Add margin-top, remove margin-bottom */
    padding: 0; /* Remove padding */
  }
  
  .screenshot-description-bold {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #263446;
    hyphens: auto; 
	text-align: justify
  }

  .screenshot-description {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    color: #263446;
    hyphens: auto; 
	text-align: justify;
    line-height: 1.5; 
  }
  
  @media (max-width: 768px) {
    .screenshot {
      flex-direction: column;
      text-align: center;
    }
  
    .screenshot img.right-margin {
      margin-right: 0;
      margin-left: 0;
      padding-bottom: 2rem;
    }

    .screenshot img.left-margin {
        margin-right: 0;
        margin-left: 0;
        padding-bottom: 2rem;
      }
  }
  
  .original-price {
    color: #999;
    text-decoration: line-through;
  }
  
  .discounted-price {
    font-weight: bold;
    color: #263446;
    font-size: 2rem;
  }
  
  .benefits {
    padding: 2rem;
  }

  .section {
    display: flex;
    justify-content: flex-start; 
    align-items: flex-start;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
    overflow-wrap: break-word;
  }

  .section-title {
    font-size: 2rem;
    font-weight: bold;
    color: #263446;
    padding: 0; /* Remove padding */
    text-align: left; /* Center the text */
  }

  @media (max-width: 768px) {
    .section {
      justify-content: center;
      align-items: center;
    }
  
    .section-title {
      text-align: center;
    }
  }
  
  .faq {
    display: flex;
    justify-content: flex-start; 
    align-items: flex-start;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
    overflow-wrap: break-word;
  }

  @media (max-width: 768px) {
    .faq {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px; /* Add padding to the left and right */
      max-width: 800px;
      margin: 0 auto;
      overflow-wrap: break-word;
    }
  }
  
  .faq-item {
    margin-bottom: 2rem;
  }
  
  /* Add new styles for FAQ icon */
  .faq-icon {
    display: inline-block;
    margin-right: 5px;
    font-weight: bold;
    font-size: 1.5rem;
    color: #0b54fc;
  }

  .faq-list  {
    list-style: none;
    padding-left: 0;
  }
  
  .plus::before {
    content: '+';
  }
  
  .minus::before {
    content: '-';
  }
  
  /* Existing styles for FAQ items */
  .faq-item {
    margin-bottom: 2rem;
  }
  
  .question {
    cursor: pointer;
    color: #263446;
    font-size: 1.1rem;
  }
  
  .answer {
    margin-top: 1rem;
    padding-left: 1rem;
    border-left: 2px solid #0366d6;
    font-size: 1.1rem;
    color: #333;
    hyphens: auto;
    text-align: justify;
    line-height: 1.5;
  }
  
  
/* Add a wrapper around all content to push the footer down */
.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure the wrapper takes at least the full viewport height */
  }
  
  /* Main content */
  .main-content {
    flex: 1; /* Allow main content to expand to fill remaining space */
  }

  /* Contact Form Styles */
.form {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f7f7f7;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }

  /* Update form styles for smaller screens */
@media (max-width: 768px) {
    .form {
      max-width: 70%; /* Adjust the maximum width for smaller screens */
      margin: 0 auto; /* Center the form horizontally */
      padding: 2rem; /* Add padding to the left and right */
      margin-bottom: 2rem;
    }
  }

  .form-field {
    margin-bottom: 1.5rem;
  }

  .form-title {
    font-size: 2rem;
    font-weight: bold;
    color: #263446;
    padding: 0; /* Remove padding */
    text-align: left; /* Center the text */
  }
  
  .form label {
    font-size: 1.1rem;
    color: #263446;
    margin-bottom: 0.5rem;
  }
  
  .form input,
  .form textarea {
    width: 100%;
    margin-top: 0.5rem;
    padding: 0.5rem;
    font-size: 1.1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form textarea {
    resize: vertical;
    min-height: 150px;
  }


  /* Style the checkbox and label */
  .form-checkbox {
    display: flex;
    align-items: flex-start;
    font-size: 1.1rem;
    margin-top: 0.5rem;
  }
  
  .form-checkbox input[type="checkbox"] {
    margin-right: 10px;
    cursor: pointer;
    flex-shrink: 0;
    width: auto;
    margin-top: 3px; 
  }

  .additional-text {
    display: block; /* Display as block */
    font-size: 1.1rem;
    margin-top: 0.5rem;
    line-height: 1.5;
    word-wrap: break-word;
  }
  
  /* Adjust the alignment of the text */
  .form-checkbox span {
    flex-grow: 1;
    line-height: 1.5;
    word-wrap: break-word;
  }

  /* Footer styles */
  .footer {
    text-align: center;
    padding: 1rem;
    background: rgb(11, 84, 252);
    background: linear-gradient(90deg, rgba(11, 84, 252, 1) 0%, rgba(21, 199, 250, 1) 100%);
    color: white;
  }
  
  .footer-text {
    text-align: center;
    color: white;
    margin: 0 0rem 0.3rem; /* Add margin-top, remove margin-bottom */
    padding: 0;
  }

  .footer-links {
    display: flex;
    justify-content: center;
    margin-top: 0rem;
  }
  
  .footer-link {
    color: white;
    text-decoration: none;
    margin: 0 1rem;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  